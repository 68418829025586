<template>
  <v-container>
    <v-dialog v-model="viewRates" max-width="400">
      <v-card>
        <v-card-title> View rates </v-card-title>
        <v-card-text>
          <div class="grid grid-cols-2 font-bold">
            <span>Value ($)</span>
            <span> Rate ({{ userCurrency }}) </span>
          </div>
          <div
            v-for="(rate, index) in cryptoData.priceVariations"
            :key="rate._id"
          >
            <div
              v-if="index + 1 === cryptoData.priceVariations.length"
              class="grid grid-cols-2"
            >
              <span>More than {{ rate.max }}</span>
              <span>{{ calcRate(rate.rate) }}</span>
            </div>
            <div v-else class="grid grid-cols-2">
              <span>Less than {{ rate.max }}</span>
              <span>{{ calcRate(rate.rate) }}</span>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="
              viewRates = false
              alert = true
            "
            block
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="alert" max-width="400" persistent overlay-opacity="0.98">
      <v-card>
        <v-card-title class="flex items-center justify-between w-full">
          <span>IMPORTANT NOTICE</span>
          <v-icon @click="$router.go(-1)">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <p>
            Please be advised that you should only send to this address once.
            Should you require sending another trade, kindly initiate a new
            order to get a new wallet; otherwise, it will not be processed.
          </p>
          <p>
            Our system automatically closes orders upon receiving the initial
            trade. Should you wish to sell additional coins, please create a new
            order.
          </p>
          <p>
            The status of your order will be updated upon confirmation from the
            BitCoin network.
          </p>
          <p>
            Our system will automatically convert your trade to your local
            currency based on the received amount. Note: Always ensure to access
            our platform to obtain a new wallet whenever you intend to trade.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="
              alert = false
              btcAmountDialog = true
            "
            block
          >
            I Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="btcAmountDialog"
      max-width="400"
      persistent
      overlay-opacity="0.98"
    >
      <v-card>
        <v-card-title> USD Amount </v-card-title>
        <v-card-text>
          <p>
            Please enter the amount of USD you want to sell. This is to ensure
            that the amount you send is the same as the amount you want to sell.
          </p>
          <v-text-field
            v-model="btcAmount"
            label="USD Amount"
            outlined
            type="number"
            suffix="USD"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            block
            @click="
              btcAmountDialog = false
              getCryptoAddress(isBITCOIN)
            "
          >
            Generate Wallet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="loadingData" opacity="0.95">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-btn text color="accent" @click="$router.go(-1)" class="mb-2">
      <v-icon left> mdi-arrow-left-thick </v-icon>
      Back
    </v-btn>
    <p class="text-subtitle-1 font-weight-bold">
      Got questions? Call or Whatsapp {{ siteData.phone }}
    </p>
    <v-card-title>
      <div class="flex items-center md:justify-start justify-between">
        <div class="md:mr-20 uppercase text-xl">SELL {{ cryptoData.name }}</div>
        <div>
          <v-btn
            :href="`/user/products/crypto/trade/${cryptoId}?XswQa=true`"
            color="primary"
            outlined
            class="text-gray-500 text-sm cursor-pointer"
            >Buy {{ cryptoData.name }}</v-btn
          >
        </div>
      </div>
    </v-card-title>
    <div>
      <div class="max-w-2xl bg-white px-3 py-5">
        <div>
          <p class="bg-gray-200 p-3 rounded-md text-sm">
            Send {{ cryptoData.name }} to this address and it will automatically
            be converted to
            {{
              userCountry === 'Nigeria'
                ? 'Naira'
                : userCountry === 'Kenya'
                ? 'Kenya SHILLINGS'
                : userCountry === 'Ghana'
                ? 'Ghana Cedis'
                : 'Naira'
            }}
            (Bases corresponding rates). Also note that you can only use this
            address once as you need to generate new address any time you want
            to trade
            <br />
          </p>
          <div class="mx-auto text-center text-sm text-gray-500 flex flex-col">
            <span class=""> Scan the QR-Code to use wallet</span>
            <div>
              <img :src="walletAddressData.barcode" alt="..." />
            </div>
            <span class="mb-5">Or copy wallet address instead</span>
          </div>
          <div class="md:flex items-center space-x-5">
            <div
              class="space-x-3 bg-gray-200 rounded-md px-3 py-4 md:inline-flex flex items-center"
            >
              <span class="w-10/12 md:w-full truncate">{{
                walletAddressData.address
              }}</span>
              <div
                @click="copy(walletAddressData.address)"
                class="cursor-pointer flex-shrink-0"
              >
                <v-icon>mdi-content-copy</v-icon>
              </div>
            </div>
            <div @click="shareMobile" class="mt-2 md:mt-0 cursor-pointer">
              <v-icon> mdi-share-variant </v-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center max-w-2xl mt-5">
        <div>
          <v-btn
            color="primary"
            primary
            block
            @click="viewRates = true"
            class="text-gray-500 text-sm cursor-pointer"
            >View rates</v-btn
          >
        </div>
        <div class="mt-2">
          <router-link to="/user/transactions">
            View transaction history
          </router-link>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import dataApi from '../../../../api/systemData'
import profile from '../../../../api/users/profile'
import { getCurrencyName } from '@/utils/countryHelpers'

export default {
  components: {},
  data() {
    return {
      getCurrencyName,
      cryptoData: {},
      walletAddressData: {},
      viewRates: false,
      cryptoId: '',
      isBITCOIN: false,
      loadingData: false,
      alert: false,
      btcAmountDialog: false,
      btcAmount: 0
    }
  },
  watch: {},
  created() {
    this.cryptoId = this.$route.params.id
    this.getCurrencyData()
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      wallet: (state) => state.user.wallet,
      siteData: (state) => state.user.siteSettings,
      countrySetting: (state) => state.user.countryCurrency
    })
  },
  methods: {
    async getCurrencyData() {
      this.loadingData = true
      const res = await dataApi.data().getSingleData('user', {
        type: 'currency',
        typeId: this.cryptoId,
        params: {
          populate: ['networks']
        }
      })
      this.cryptoData = res.data.data
      if (res.data.data.symbol === 'BTC') {
        this.isBITCOIN = true
        // this.getCryptoAddress(this.isBITCOIN)
        // this.alert = true
        this.viewRates = true
      } else {
        this.isBITCOIN = false
        this.getCryptoAddress(false)
      }
      this.loadingData = false
    },
    async getCryptoAddress(isBTC) {
      this.loadingData = true

      let req

      if (isBTC) {
        req = await profile.profile().sellBTC({
          coinId: this.cryptoId,
          amount: this.btcAmount
        })
        if (req.error) {
          this.loadingData = true
          // this.generateCryptoAddress()
        }
        this.walletAddressData = req.data.data
      } else {
        req = await profile.profile().getCryptoAddress(this.cryptoId)

        if (
          Array.isArray(req.data.data.data) &&
          req?.data?.data?.data?.length === 0
        ) {
          this.loadingData = true
          this.generateCryptoAddress()
          return
        }

        this.walletAddressData = req.data.data.data.find(
          (wallet) => wallet.coin === this.cryptoId
        )
      }

      if (req.error) {
        this.$toast.error(req.message)
        this.loadingData = false
        return
      }

      // if (!this.walletAddressData) {
      //   this.loadingData = true
      //   this.generateCryptoAddress()
      //   return
      // }

      this.loadingData = false
    },
    async generateCryptoAddress() {
      this.loadingData = true

      const req = await profile.profile().generateCryptoAddress(this.cryptoId)
      this.walletAddressData = req.data.data

      if (req.error) {
        this.$toast.error(req.message)
        this.loadingData = false
        return
      }

      this.loadingData = false
    },
    copy(address) {
      const input = document.createElement('input')
      input.value = address

      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
    },
    shareMobile() {
      if (!navigator.canShare) return
      navigator
        .share({
          title: 'Snappy Exchange',
          text: `Send bitcoins to your address and it will automatically be converted to NAIRA or CEDIS (Depending on you default fiat wallet) and based on the corresponding rates`,
          url: this.walletAddressData.address
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error))
    },
    calcRate(rate) {
      return Number(
        Number(rate) /
          (this.countrySetting?.[this.userCountry?.toLowerCase()].nairaRate ||
            1)
      ).toFixed(2)
    }
  }
}
</script>
